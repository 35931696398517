.Toastify__toast {
    border-radius: 10px;
    width: 328px;
}

.Toastify__toast--success {
    border-left: 13px solid var(--green);
}

.Toastify__toast--error {
    border-left: 13px solid var(--alert-red);
}

.Toastify__toast-body {
    column-gap: 8px;
}

.toast-title {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: var(--dark-blue);
}

.toast-description {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: var(--dark-blue);
}