.btn {
  padding: 6px 12px;
  border: 1px solid var(--red);
  box-shadow: none;
  border-radius: 4px;
  background-color: var(--red);
  color: var(--white);
  width: auto;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;

  &:hover {
    border: 1px solid var(--maroon);
    background-color: var(--maroon);
  }

  &:focus-visible {
    outline: none;
    border: 1.5px solid var(--ocean-blue);
  }
}

.border-btn {
  background-color: transparent;
  color: var(--red);

  &:hover {
    color: var(--white);
  }
}

.btn:disabled {
  opacity: 0.6;
  pointer-events: none;
}

.btn-loader {
  width: 10px;
  height: 10px;
  /* margin: -76px 0 0 -76px; */
  border: 3px solid var(--silver-grey);
  border-radius: 50%;
  border-top: 3px solid var(--grey);
  animation: spin 2s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}