.denied {
    text-align: center;
    margin-top: 12%;
}

.text {
    font-size: 2rem;
    line-height: 1;
    color: var(--hines-red);
    margin-bottom: 16px;
}

.message {
    font-size: 18px;
    font-family: var(--font-family);

    a {
        color: var(--hines-red);
    }
}

.icons {
    font-size: 28px;
    color: var(--hines-red);
}