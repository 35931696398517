@import "../../styles/utils/global.scss";

.imageSection {
    width: 47%;
    background-image: var(--login);
    background-position: 70% center;
    background-size: cover;
    position: relative;

    &::after {
        content: '';
        // background: rgb(34 34 34 / 20%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.loginImage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5vw;
    color: var(--white);
    max-width: 500px;
    margin: 0 auto;

    .imageHeading {
        font-size: 48px;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: 0.48px;
        margin-bottom: 25px;
        font-family: var(--font-family);
    }

    .imageContent {
        padding-bottom: 2vw;
        margin-top: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        font-family: var(--font-family);
    }
}