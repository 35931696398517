 .container {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     height: 100vh;
     background-color: #f4f4f4;
     font-family: var(--font-family);
 }

 .heading {
     font-size: 24px;
     color: var(--black);
 }

 .message {
     font-size: 16px;
     color: var((--black));
 }