.header {
    position: -webkit-sticky;
    position: sticky;
    z-index: 999;
    top: 0;
    background-color: var(--white);
    padding: 1px 24px 0px;
    box-shadow: 0 4px 2px -4px gray;
}

.logoContainer {
    cursor: pointer;
}

.innerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.left {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.logo {
    width: 80px;
}

.navLink {
    list-style: none;
    display: flex;
    // column-gap: 8px;
    margin-bottom: 0;

}

.navItems {
    padding: 10px 8px 14px;
    margin-right: 8px;

    a {
        text-decoration: none;
        color: var(--dark-blue);
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
}

.subNav {
    padding-bottom: 12px;

    a {
        text-decoration: none;
        color: var(--dark-blue);
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }

    &:first-child {
        padding-top: 0px;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &.active {
        border: none;
    }
}

.active {
    border-bottom: 3px solid var(--hines-red);

    a {
        color: var(--maroon);
        font-weight: 500;
    }
}

.userLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--azure-blue);
    color: var(--white);
    cursor: pointer;
    border: none;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-family);
}

.popOver {
    position: absolute;
    right: 0;
    margin: 4px 0 0;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    ;
}

.popList {
    list-style: none;
    margin: 0;
    padding: 12px 24px;

    button {
        background: none;
        border: none;
        display: flex;
        // column-gap: 8px;
        font-size: 14px;
        font-family: var(--font-family);
        font-weight: 400;
        align-items: center;
        color: var(--black);
        cursor: pointer;

        svg {
            margin-right: 8px;
        }
    }

    li {
        cursor: pointer;
    }

    .profile {
        // display: flex;
        // column-gap: 3px;
        // padding: 1px 5px;
        // text-decoration: none;
        // color: var(--black);
        margin-bottom: 10px;
    }
}

.product {
    display: flex;
    position: relative;
    align-content: center;
    left: -10px;
    cursor: pointer;

    span {
        right: -24px;
        top: 32px;
        margin-top: 12px;
        min-width: 155px;
    }
}

.arrow {
    border: none;
    color: var(--dark-blue);
    background-color: transparent;
    cursor: pointer;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 8px 15px;

    &::after {
        content: "";
        position: relative;
        border: solid var(--light-grey);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        height: 0;
        margin-left: 8px;
    }
}

.up {
    &::after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 2px;
    }
}

.down {
    &::after {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: -2px;
    }
}