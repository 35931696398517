.label {
  display: block;
  padding: 8px 0;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  pointer-events: none;
}

.inputs {
  border: 1px solid var(--cerebral-grey);
  color: var(--black);
  border-radius: 3px;
  padding: 12px 15px;
  display: block;
  width: calc(100% - 32px);
  font-family: var(--font-family);

  &:focus-visible {
    outline: none;
    border: 1.5px solid var(--ocean-blue);
  }

  &.error-outline {
    border: 1px solid var(--hines-red);
  }

  &:disabled {
    color: var(--dark-blue);
    background-color: #f2f2f2;
  }
}

// input::placeholder {
//   font-size: 14px;
// }

.flex-input {
  display: flex;

  .inputs {
    padding-right: 24px !important;
  }
}

.error {
  color: var(--red);
  font-family: var(--font-family);
  font-size: 14px;
  margin-bottom: 0;
  word-break: break-word;
}

.css-t3ipsp-control {
  border-color: var(--cerebral-grey) !important;
  box-shadow: none !important;
}

.inputWrapper {
  position: relative;
  width: 100%;

  .inputs {
    width: calc(100% - 56px);
    padding-right: 40px;
  }

  .passwordToggleBtn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.basic-single {
  font-family: var(--font-family);
  font-size: 14px;

  div {
    margin-top: 0;
  }

  .select__menu {
    background-color: #C1C5CD;
    color: #17212B;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 0;

    >div {
      padding: 0;
      margin: 0;

      >div {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.errorMessage {
  margin-bottom: 0 !important;
}

.css-hlgwow {
  padding: 13px 15px !important;
}

textarea {
  min-height: 80px;
  resize: none;
}

.select__input-container {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 0px !important;
}

.select__placeholder {
  font-family: var(--font-family);
  font-size: 14px;
}

.select__option {
  padding: 10px 12px !important;
}