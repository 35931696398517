.skip-link {
    position: absolute;
    top: -40px;
    left: 10px;
    background-color: #000;
    color: #fff;
    padding: 8px;
    z-index: 9999;
    text-decoration: none;
    transition: top 0.3s ease;
}
  
.skip-link:focus {
    top: 10px;
}