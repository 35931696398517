.logIn {
  display: flex;
  height: 100vh;
  position: relative;
}

@supports (height: 100dvh) {
  .logIn {
    height: 100dvh;
  }
}

.regularHeight {
  @media screen and (max-height:870px) {
    min-height: 880px;
  }
}

.qrHeight {
  @media screen and (max-height:980px) {
    min-height: 1000px;
  }
}

.brandLogo {
  color: var(--hines-red);
  font-size: 50px;
  font-family: "Times New Roman", Times, serif;
  margin-bottom: 30px;
}

.innerContent {
  display: flex;
  width: 100%;

  .formwrap {
    margin-top: 30px;
  }

  .qrForm {
    margin-top: 10px;
  }

  .formSection {
    width: 54%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    font-family: var(--font-family);
    font-size: 14px;
    position: relative;
    padding-bottom: 80px;

    // form {
    //   margin-top: 30px;
    // }

    label {
      color: var(--dark-grey);
    }

    a {
      text-decoration: none;
      color: var(--ocean-blue);
      font-size: 14px;
    }

    .qrCodeImage {
      svg {
        margin: 10px 0;
      }
    }

    .topSpacing {
      padding-top: 70px;
    }

    .helpSection {
      position: absolute;
      bottom: 64px;

      // @media screen and (max-height:860px) {
      //   bottom: 28px;
      // }

      // @media screen and (max-height:820px) {
      //   bottom: 0;
      // }

      .helpEmail,
      .contactLink {
        color: var(--light-grey);
        font-size: 14px;
        margin: 4px 0;

        a {
          text-decoration: underline;
        }
      }

      .contactLink {
        word-break: break-all;
        // max-width: 300px;
      }

      .helpEmail {
        text-decoration: underline;
      }

      .hinesText {
        margin-top: 30px;
        font-size: 12px;
        color: var(--light-grey);
      }
    }

    .checkboxRow {
      margin: 12px 0;
      display: flex;
      align-items: center;
    }

    .checkbox {
      width: 1rem;
      height: 1rem;
      margin-right: 10px;
      border-radius: 4px;
      appearance: none;
      background-color: var(--white);
      border: 1px solid var(--black);
      position: relative;
      cursor: pointer;

      &:checked {
        background-color: var(--white);
        border-color: var(--hines-red);

        &::before {
          content: "";
          left: 4px;
          position: absolute;
          top: 0px;
          transform: translate(-50%, -50%);
          height: 10px;
          width: 4px;
          border-bottom: 2px solid var(--hines-red);
          border-right: 2px solid var(--hines-red);
          transform: rotate(45deg);
        }
      }
    }

    .checkboxLabel {
      margin: 0;
    }

    >div {
      width: 300px;

      >div {
        .changePasswordText {
          font-size: 14.75px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          margin: 20px 0;
        }
      }
    }
  }
}

.signInBtn,
.SsoBtn {
  padding-top: 22px;

  button {
    width: 100%;
  }
}

.SsoBtn {
  button {
    background-color: white;
    color: var(--red);
  }

  button:hover {
    cursor: pointer;
    background-color: var(--red);
    color: white;
  }
}

.verifyBtn {
  padding-top: 30px;
}

.signInText {
  text-align: center;
}

.fieldContainer {
  padding: 8px 0px;
}

.errMsg,
.mfaErrMsg {
  font-size: 14px;
  font-family: var(--font-family);
  line-height: 20px;
  font-weight: 400;
}

.mfaErrMsg {
  margin-bottom: 0;
}

.credErrMsg {
  margin: 6px 0;
}

@media screen and (min-width: 2000px) and (min-height: 1200px) {
  .innerContent {
    .formSection {
      margin-top: 34vh !important;
    }
  }
}