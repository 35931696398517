@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

// @font-face {
//   font-family: 'DM Sans';
//   src: url('/assets/fonts/DMSans-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }

:root {
  // For global access without imports
  --black: #000000;
  --red: #b70021;
  --white: #ffffff;
  --maroon: #82080d;
  --grey: #878c96;
  --dark-grey: #4B5257;
  --light-grey: #757E82;
  --ocean-blue: #008AB0;
  --almost-white: #f2f2f2;
  --light-almost-white: #f0f0f0;
  --silver-grey: #f3f3f3;
  --charcoal-grey: #212529;
  --grey-blue: #d1d4d6;
  --slate-grey: #4B5257;
  --cerebral-grey: #cccccc;
  --light-silver: #ddd;
  --off-white: #f4f4f4;
  --ghost-white: #f9f9f9;
  --anti-flash-white: #f1f1f1;
  --pearl-grey: #e9e9e9;
  --hines-red: #B70021;
  --snow-grey: #E3E5E6;
  --green: #25A249;
  --alert-red: #DA1E28;
  --dark-blue: #17212B;
  --azure-blue: #212F36;
  --active-green: #93F48A;
  --inactive-yellow: #E8D333;
  --commercial-blue: #DBEEFE;
  --seperator-grey: #CCD3D7;
  --blue: #3775DB;
  --table-grey: #F6F7FA;
  --cyan: #3EFFDF;
  --orange: #FF9933;
  --aqua: #E6F3F7;
  --vogue: #002D52;

  //font

  --font-family: "DM Sans",
    sans-serif;

  //images

  --edit: url("../../assets/icons/edit-icon.svg");
  --vector: url("../../assets/icons/Vector.svg");
  --login: url("../../assets/auth/Hines_T3_Bayside_8137L.jpg");
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

body {
  margin: 0;
}

input::placeholder {
  // font-size: 14px;
  font-family: var(--font-family);
  color: #808080;
  // margin-top: -2px;
}

input::-webkit-input-placeholder {
  color: #808080;
}

/* Mozilla Firefox */
input::-moz-placeholder {
  color: #808080;
}

/* Internet Explorer / Edge */
input:-ms-input-placeholder {
  color: #808080;
}

textarea::placeholder {
  font-family: var(--font-family);
  color: #808080;
  font-size: 13px;
}

/* WebKit Browsers (Chrome, Safari) */
textarea::-webkit-input-placeholder {
  color: #808080;
}

/* Mozilla Firefox */
textarea::-moz-placeholder {
  color: #808080;
}

/* Internet Explorer / Edge */
textarea:-ms-input-placeholder {
  color: #808080;
}

.disable {
  opacity: 0.6;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

$headingFont: "Source Serif 4", serif;
$baseFont: "Roboto", serif;

button {
  font-family: var(--font-family);
}

textarea {
  &:focus-visible {
    outline: none;
    border: 1.5px solid var(--ocean-blue);
  }
}

small {
  font-family: var(--font-family);
}

.page-title {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  /* Visually hides the element */
  overflow: hidden;
}

.product-form,
.budget-form {
  .react-datepicker {
    font-family: var(--font-family) !important;
  }

  .react-datepicker__year-wrapper {
    justify-content: center;

    .react-datepicker__year-text--disabled {
      cursor: not-allowed !important;
      pointer-events: none !important;
      // display: none;
    }

    .react-datepicker__year-text--keyboard-selected {
      background-color: var(--white);
      color: var(--black);

      &:hover {
        border-radius: 0.3rem;
        background-color: #f0f0f0;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    input {
      background: url('../../assets/icons/calendar-icons.svg') no-repeat right;
      background-size: 20px;
      background-position-x: 98%;
      cursor: pointer;
    }
  }

  .react-datepicker-popper {
    .react-datepicker__year-text--selected {
      background-color: var(--hines-red) !important;
    }
  }

  .date-picker-error {
    border: 1px solid var(--red) !important;
    /* Red border for error state */
  }

  .date-picker-error:focus {
    outline: none;
    /* Highlight on focus */
  }
}

.datepicker-filter {
  .react-datepicker {
    font-family: var(--font-family) !important;
  }

  //.react-datepicker__day--today
  .react-datepicker__day--selected {
    background-color: var(--hines-red);
    color: var(--white);
  }

  // .react-datepicker__day--selected {
  //   &:hover {
  //     background-color: var(--hines-red);
  //     color: var(--white);
  //   }

  //   &:not([aria-disabled=true]):hover {
  //     background-color: var(--hines-red) !important;
  //     color: var(--white) !important;
  //   }
  // }

  // .react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover {
  //   &:hover {
  //     background-color: var(--hines-red);
  //     color: var(--white);
  //   }
  // }

  // .react-datepicker__day--today {
  //   &:hover {
  //     color: var(--black);
  //   }
  // }

  .react-datepicker__year-wrapper {
    justify-content: center;

    .react-datepicker__year-text--disabled {
      cursor: not-allowed !important;
      pointer-events: none !important;
      // display: none;
    }

    .react-datepicker__year-text--keyboard-selected {
      background-color: var(--white);
      color: var(--black);

      &:hover {
        border-radius: 0.3rem;
        background-color: #f0f0f0;
      }
    }
  }

  .react-datepicker-wrapper {
    width: 85%;

    input {
      background: url('../../assets/icons/calendar-icons.svg') no-repeat right;
      background-size: 20px;
      background-position-x: 98%;
      // background-position-y: -600%;
      cursor: pointer;
      border: none;
      border-bottom: 1.5px solid var(--light-grey);
      padding-bottom: 6px;
      caret-color: transparent;
      border-radius: 0px;
      width: 100%;

      &:focus-visible {
        outline: none;
        border: none;
        border-bottom: 1.5px solid var(--ocean-blue);
      }

      &:disabled {
        background-color: #f2f2f2 !important;
        color: var(--dark-blue) !important;
      }
    }
  }

  .react-datepicker-popper {
    .react-datepicker__year-text--selected {
      background-color: var(--hines-red) !important;
    }
  }

  .date-picker-error:focus {
    outline: none;
    /* Highlight on focus */
  }
}