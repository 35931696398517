@import "../../../styles/utils/global.scss";

.changePassword {
    display: flex;
    justify-content: center;
    height: calc(100dvh - 59px);
}

.changePasswordLogged {
    display: flex;
    justify-content: center;
    height: calc(100dvh - 65px);
    @media (max-height: 720px) {
        height: 735px;
        overflow-y: auto;
    }
}

.brandLogo {
    color: var(--red);
    font-size: 70px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
}

.innerContent {
    display: flex;
    width: 100%;
    
    .formSection {
        width: 54%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        label {
            color: var(--dark-grey);
        }

        >div {
            width: 300px;
            >div {
                .changePasswordText {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    font-family: var(--font-family);
                    margin: 14px 0;
                }
            }
        }

        form {
            >div {
                padding: 8px 0;
                &.changePasswordBtn {
                    padding-top: 16px;
                }
            }
        }
    }
    .loginImage {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 5vw;
        color: var(--white);
        max-width: 500px;
        margin: 0 auto;
        .imageHeading {
            font-size: 48px;
            font-weight: 400;
            line-height: 56px;
            letter-spacing: 0.48px;
            margin-bottom: 25px;
            font-family: $headingFont;
        }
        .imageContent {
            padding-bottom: 2vw;
            margin-top: 0;
            font-family: $baseFont;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
        }
    }
}

.changePasswordBtn {

    button {
        width: 100%;
        &.undefined {
            pointer-events: none;
            opacity: 0.5;
        }
    }
    .undefined {
        pointer-events: none;
        opacity: 0.5;
    }
}

.errorMsg {
  color: var(--red);
  font-family: var(--font-family);
  font-size: 14px;
}