.notFound {
    text-align: center;
    // margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.code {
    font-size: 15vw;
    line-height: 1;
    color: var(--hines-red);
    margin-bottom: 16px;
    margin-top: 0;
}

.message {
    font-size: 18px;
    font-family: var(--font-family);

    a {
        color: var(--hines-red);
    }
}