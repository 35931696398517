.loader {
  position: absolute;
  left: 52%;
  top: 50%;
  z-index: 1010;
  width: 60px;
  height: 60px;
  margin: -76px 0 0 -76px;
  border: 10px solid var(--silver-grey);
  border-radius: 50%;
  border-top: 10px solid var(--grey);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@media screen and (max-width:1150px) {
  .loader {
    left: 55%;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  background: var(--charcoal-grey);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 9990;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.4;
}